import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout';
import fiskalneImg from '../images/fiskalne.png';
import fiskalne2 from '../images/fiskalne2.png';
import Map from '../containers/map.js';
import {
    Container, Row, Col
} from 'reactstrap';

export class fiskalne extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="fiskalne">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>Fiskalne kase</h1>


                        <p><Link to="/">Početna</Link> / Fiskalne kase</p>
                    </div>

                    <div className="content">
                        <h1>FISKALNE KASE</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" md="12">
                                <img src={fiskalneImg} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="6" md="12">
                                <p>Program za fiskalne uređaje  u maloprodaji i veleprodaji namijenjenjen je svim maloprodajnim i velprodajnim objektima koji imaju fiskalne uređaje i omogućava brz rad prilikom izdavanja fiskalnih računa, bezgotovinskih računa, izrade kalkulacija, kao i mnogih drugih opcija. Sve obaveze oko fiskalizacije završavamo za Vas u što kraćem roku. Preuzimate uređaj spreman za rad sa unesenom bazom artikala. Obezbjeđena Vam je adekvatna obuka na našem testnom uređaju uz uputstva za rad. U ponudi imamo veliki izbor fiskalnih uređaja. </p>

                            </Col>
                            <Col xl="12" lg="12" md="12" md="12">
                                <p>POS SISTEM I OPREMA</p><p>

Možemo vam ponuditi kompletne i fleksibilne POS usluge, od izbora POS terminala do razvoja i integracije softvera.
Plaćanje robe ili usluge mora biti jednostavno, brzo i sigurno iskustvo. Cilj nam je osigurati da se proces plaćanja nesmetano prilagođava životima kupaca, a ne obrnuto.
</p>
                                <img src={fiskalne2} className="img-fluid" />

                                <p></p>
                            </Col>


                        </Row>

                    </div>
                </Container>
                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(fiskalne)
