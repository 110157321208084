import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import {Link} from 'react-router-dom';
import ideja from '../images/ideja.svg';
import planiranje from '../images/plan.svg';
import razvijanje from '../images/razvijanje.svg';
import testiranje from '../images/testiranje.svg';
import ScrollAnimation from 'react-animate-on-scroll';

import {
    Container, Row, Col, Button
} from 'reactstrap';

export class strategy extends Component {
    render() {
        return (
            <div className="strategy">
                <Container>
                    <Row>
                        <Col xl={{ size: 6, order: 0 }} lg={{ size: 6, order: 1 }} md="12" xs={{ size: 12, order: 1 }}>
                            <div className="wrapBox">
                                <Row>

                                    <Col xl="6" lg="6" md="6" xs="6">
                                        <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                            <div className="box">
                                                <div className="d-flex">
                                                    <Isvg src={ideja} />
                                                    <h4>Ideja</h4>
                                                </div>
                                                <p>Svi naši projekti počinju
                                     smišljanjem kreativnih ideja. </p>
                                            </div>
                                        </ScrollAnimation>
                                    </Col>

                                    <Col xl="6" lg="6" md="6" xs="6">
                                        <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                            <div className="box">
                                                <div className="d-flex">
                                                    <Isvg src={planiranje} />
                                                    <h4>Planiranje</h4>
                                                </div>
                                                <p>Veoma važan korak u razvoju da
                                     bi se izbjegle potencijalne greške. </p>
                                            </div>
                                        </ScrollAnimation>
                                    </Col>

                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                        <div className="box">
                                            <div className="d-flex">
                                                <Isvg src={razvijanje} />
                                                <h4>Razvoj</h4>
                                            </div>
                                            <p>Koristimo poslednje tehnologije za
                                    razvoj poslovnih aplikacija.</p>
                                        </div>
                                    </ScrollAnimation>
                                    </Col>

                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                        <div className="box">
                                            <div className="d-flex">
                                                <Isvg src={testiranje} />
                                                <h4>Testiranje</h4>
                                            </div>
                                            <p>Naš tim prati skup pravila I
                                     procedura za testiranje softvera.</p>
                                        </div>
                                    </ScrollAnimation>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                        <Col xl={{ size: 5, order: 1 }} lg={{ size: 6, order: 1 }} md="12" xs={{ size: 12, order: 0 }} className="offset-xl-1">
                            <div className="boxStrategy">
                                <h1>Naša strategija</h1>
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                <p>Mi smo kompanija koja je usmjerena na Vaš rast. Održavanje visokog nivoa usluge za nas je glavni prioritet. Klijenti očekuju 100% dostupnost i trudimo se da ispunimo takva očekivanja 24 sata dnevno, 7 dana u sedmici. </p>
                                 </ScrollAnimation>
                                <Link to = "/about"><Button><span>DETALJNIJE</span></Button></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default strategy
