import React, { Component } from 'react'
import DefaultLayout from '../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import aboutImg from '../images/about.png';
import { Container, Row, Col } from 'reactstrap';
import Partners from '../containers/partners';
import Map from '../containers/map.js';
import logo2 from '../images/logo2.svg';
import Isvg from 'react-inlinesvg';
export class about extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="aboutPage">

                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>O nama</h1>


                        <p><Link to="/">Početna</Link> / O nama</p>
                    </div>
                    <div className="content">
                        <h1>BSS SOLUTIONS</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                            <Isvg src={logo2}  className="img-fluid"/>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <p> Bss solutions je  osnovan  2008. godine, sa ciljem da pruži vrhunska softverska rješenja za Vaše  poslovanje i na taj način dodamo vrijednost Vašem poslu. Specijalizovani smo za usluge izrade knjigovodstvenih programa za mala i srednja preduzeća, usluge izrade i održavanja softvera za fiskalne uređaje, prodaju i servisiranje fiskalnih kasa, računara i POS opreme,  usluge izrade i dizajna modernih web stranica, web aplikacija, projektovanje  mreža i VPN mreže. Nudimo, kvalitet, sigurnost, brzinu, originalnost i pristupačne cijene. 
Naša vizija je da se fokusiramo na pružanje vrhunskih usluga klijentima kojima su potrebna najbolja softverska rješenja za optimalno poslovanje, te na taj način doprinesemo ostvarivanju najboljih poslovnih rezultata, uz naš  kvalitetan i stručan tim na čelu sa iskusnim programerima. 


                               </p>
                                <p>Budući da Vaše poslovanje zavisi od tačnosti i sigurnosti, težimo izvrsnosti u svemu što preduzimamo.
Naš posao sastoji se u prilagodljivosti i predviđanju potreba naših klijenata i razvijanju novih usluga kako bi se zadovoljile potrebe i specifični zahtjevi naših klijenata.
 Važno nam je da klijent bude zadovoljan, jer njegov uspjeh je i naš uspjeh. Ukoliko to tražite, na pravom ste mjestu.

 
                               </p>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <p></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="partnersAboutWrapper">
                    <Partners />
                </div>

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>

            </div>
        )
    }
}

export default DefaultLayout(about)
