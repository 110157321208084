import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout.js';
import { Container, Row, Col } from 'reactstrap';
import posOpremaImg from '../images/slickSlider/pos-oprema.jpg';
import Map from '../containers/map.js';
import Marketing from '../containers/marketing.js';
export class posOprema extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;
        const lang2 = 44.7569511;
        const lat2 = 19.2150224;
        return (
            <div className="knjigovodjstvoAzure">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>OPREMA</h1>


                        <p><Link to="/">Početna</Link> / Software</p>
                    </div>
                    <div className="content">
                        <h1>POS OPREMA</h1>

                        <Row>
                            <Col xl="7" lg="7" md="7" xs="12">
                                <p>Možemo vam ponuditi kompletne i fleksibilne POS usluge, od izbora POS terminala do razvoja i integracije softvera.
Plaćanje robe ili usluge mora biti jednostavno, brzo i sigurno iskustvo. Cilj nam je osigurati da se proces plaćanja nesmetano prilagođava životima kupaca, a ne obrnuto.
</p>
                            </Col>
                            <Col xl="5" lg="5" md="5" xs="12">
                                <img src={posOpremaImg} className="img-fluid" />
                            </Col>
                  
                        </Row>
                    </div>

                </Container>
                <Marketing />
                <Row noGutters>
                    <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>

        )
    }
}

export default DefayltLayout(posOprema)
