import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';
import Clients from '../images/hands/clients.png';
import Clock from '../images/hands/clock.png';
import Project from '../images/hands/project.png';

export class hands extends Component {
    render() {
        return (
            <div className="hands">
                <div className="undoRotate">
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="d-block">
                                    <h5>KLIJENTI</h5>
                                    <img src={Clients} className="img-fluid" />
                                    <h6>375</h6>
                                </div>
                            </Col>
                            <Col xl="4" lg="4" md="4" xs="12">
                                
                            <div className="d-block">
                                <h5>PROJEKTI</h5>
                                    <img src={Project} className="img-fluid" />
                                    <h6>50</h6>
                                </div>
                            </Col>
                            <Col xl="4" lg="4" md="4" xs="12">
                            <div className="d-block">
                                <h5>DOSTUPNI</h5>
                                    <img src={Clock} className="img-fluid" />
                                    <h6>24/7</h6>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default hands
