import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout.js';
import Partners from '../containers/partners.js';
import BSSicon from '../images/BSSicon.png';
import Map from '../containers/map.js';
import Hands from '../containers/hands.js';
import { Container, Row, Col, Button } from 'reactstrap';

export class knjigovodjstvoProizvodnja extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;
   
        return (
            <div className="knjigovodjstvo">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>SOFTWARE</h1>


                        <p><Link to="/">Početna</Link> / Software</p>
                    </div>
                    <div className="content">
                        <h1>KNJIGOVODSTVO</h1>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="slika">
                                <img src={BSSicon} className="img-fluid" />
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="7" xs="12" className="offset-xl-1 offset-lg-1 ">
                                <h6>Proizvodnja</h6>
                                <p>Program za proizvodnju uvijek u preduzeću predstavlja osnovni posao i kao takav je jedan od najvrijednijih resursa proizvodnog preduzeća. Obezbjeđivanje proizvodnje dobrim softverom uvijek ima jako velik uticaj na profit preduzeća. Zbog toga su naša softverska rješenja za proizvodnju daleko najisplativija i omogućavaju najbrži povrat na uloženu investiciju.
                                </p>
                            </Col>
                            <Col xl="12">
                                <p>Programi za proizvodnju i programi za planiranje su priagođeni u različitim tipovima proizvodnje: serijska proizvodnja, pojedinačna proizvodnja, prototipovi, projektna proizvodnja…
Svi elementi programa za proizvodnju se mogu prilagoditi kupcu ili povezati sa bilo kojim drugim programom,i mogu uvijek da se naprave po mjeri kupca. Programi za proizvodnju koje kreiramo mogu da sadrže upite, ponude, narudžbenice, kalkulacije proizvodnje, planiranje proizvodnje, radne naloge, tehnološku dokumentaciju, praćenje izvršenja po resursima, obračune i analize proizvodnje, planiranje potreba materijala, normative, evidencije radnog vremena, skladište materijala, skladište alata i opreme, skladište poluproizvoda i gotovih proizvoda, prijemnice, otpremnice, povratnice, reklamacije, upravljanje zalihama na skladištu, strategije naručivanja, rezervacije, zaduženja, transport i vozni park, distribuciju, kontakte, odnose s partnerima, sistem kvaliteta i još mnogo toga, a sve po zahtjevima i potrebama naših klijenata.
 </p>
                            </Col>
                        </Row>

                    </div>
                </Container>
                <Hands/>
                <Partners />

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(knjigovodjstvoProizvodnja)
