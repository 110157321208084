import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import logo from '../images/logoFooter.svg';

import {
    Button, Container, Input, FormGroup, Col, Row
} from 'reactstrap';
export class footer extends Component {

    render() {
        return (
            <div className="footerWrap">
                <div className="footer">
                <Container>
                    <Row>
                        <Col xl="3" lg="3" md="3">
                            <Isvg src={logo} />
                            <p>
Bss solutions je  osnovan  2008. godine, sa ciljem da pruži vrhunska softverska rješenja za Vaše  poslovanje i na taj način dodamo vrijednost Vašem poslu. 
                            </p>
                        </Col>
                        <Col xl="2" lg="2" md="2" xs="3" className="offset-xl-1 offset-lg-1 offset-md-1">
                        <h3>NAVIGACIJA</h3>

                            <div >
                                <Link to="/">Početna</Link>
                                <Link to="/about">O nama</Link>
                                <Link to="/knjigovodjstvoMaloProdaja">Software</Link>
                                <Link to="/fiskalne">Fiskalne kase</Link>
                            </div>
                        </Col>
                        <Col xl="2" lg="2" md="2" xs="3">
                            <div className="shortNav">
                            <Link to="#">Web rješenja </Link>
                            <Link to="/reference">Reference</Link>
                            <Link to="/kontakt">Kontakt</Link>
                            </div>

                        </Col>
                        <Col xl="3" lg="4" md="4" xs="6"  className="offset-xl-1" >
                            <h3>KONTAKT</h3>

                            <FormGroup>
                                <Input type="text" placeholder="Ime" />
                                <Input type="text" placeholder="Prezime" />
                                <Input type="textarea" placeholder="Vaša poruka" />
                                <Button><span>POŠALJI</span></Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Container>
                </div>
                
                <div className="copyright">
                    <Container>
                        <div className="d-block d-md-flex  justify-content-between">
                    <p>Copyright © BSS Solutions  - 2019. Sva prava zadržana.</p>
                    <p>  Created by <span>BSS Solutions</span> </p>
                    </div>
                    </Container>
                </div>
            </div>
        )
    }
}

export default footer
