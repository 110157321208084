import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import {Link} from 'react-router-dom'


import head from '../images/head.svg';
import clock from '../images/clock.svg';
import database from '../images/database.svg';
import kvalitet from '../images/kvalitet.svg';
import klijenti from '../images/klijenti.svg';
import phone from '../images/phone.svg';
import ScrollAnimation from 'react-animate-on-scroll';

import {
    Container, Row, Col, Button
} from 'reactstrap';
export class about extends Component {
    render() {
        return (
            <div className="about">
                <Container>
                    <Row>
                        <Col xl="5" lg="5" md="5" xs="12">

                            <h1>O nama</h1>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>

                            <p>Bss solutions je  osnovan  2008. godine, sa ciljem da pruži vrhunska softverska rješenja za Vaše  poslovanje i na taj način dodamo vrijednost Vašem poslu. Specijalizovani smo za usluge izrade knjigovodstvenih programa za mala i srednja preduzeća, usluge izrade i održavanja softvera za fiskalne uređaje...
</p>

                            <Link to = "/about"><button className="btn"><span>DETALJNIJE</span></button></Link>
                            </ScrollAnimation>
                        </Col>

                        <Col xl="6" lg="7" md="7" xs="12" className="addPadding offset-xl-1">
                            <Row>
                                <Col xl="4" lg="4" md="4" xs="6">
                                    <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                        <div className="box">
                                            <Isvg src={head} />
                                            <h4>EKSPERTI I <br />PROFESIONALCI</h4>
                                        </div>
                                    </ScrollAnimation>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">                               
                                 <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                    <div className="box">
                                        <Isvg src={clock} />
                                        <h4>DUGOGODIŠNJE <br />ISKUSTVO</h4>
                                    </div>
                                </ScrollAnimation>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <Isvg src={database} />
                                        <h4>PRILAGODLJIVA <br />TEHNOLOGIJA</h4>
                                    </div>
                                </ScrollAnimation>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="box">
                                        <Isvg src={kvalitet} />
                                        <h4>KVALITET<br /> U POSLU</h4>
                                    </div>
                                </ScrollAnimation>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">
                                <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                    <div className="box">
                                        <Isvg src={klijenti} />
                                        <h4>ZADOVOLJNI<br /> KLIJENTI</h4>
                                    </div>
                                </ScrollAnimation>
                                </Col>
                                <Col xl="4" lg="4" md="4" xs="6">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <Isvg src={phone} />
                                        <h4>BRZA<br /> PODRŠKA</h4>
                                    </div>
                                </ScrollAnimation>
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Container>
            </div>
        )
    }
}

export default about
