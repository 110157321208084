import React, { Component } from 'react'
import picture1 from '../images/carousel1.png';
import picture2 from '../images/carousel2.png';
import picture3 from '../images/carousel3.png';
import picture4 from '../images/carousel4.png';
import picture5 from '../images/carousel5.png';
import picture6 from '../images/carousel6.png';
import { Link } from 'react-router-dom';
import SkrolDole from '../images/svg/skrolDole.svg';
import Isvg from 'react-inlinesvg';
import sliderIcon1 from '../images/carouseIcon1.png';
import sliderIcon2 from '../images/carouseIcon2.png';
import sliderIcon3 from '../images/book.png';
import sliderIcon4 from '../images/slickSlider/pos-oprema.jpg';
import sliderIcon5 from '../images/kase.png';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Button
} from 'reactstrap';

const items = [
    {
        src: picture1,
        sliderIcon: sliderIcon1, 
        title: 'BSS APLIKACIJA',
        description: 'Kompletna poslovna aplikacija iz vlastite proizvodnje za mala i srednja preduzeća',
        btnLink: '#',
    },
    {
        src: picture2,
        sliderIcon: sliderIcon2, 
        title: 'WEB SOLUTIONS',
        description: 'Izrada modernih web sajtova, web aplikacija, online prodavnica, web dizajn, hosting.',
        btnLink: '#',

    },
   /* {
        src: picture3,
        sliderIcon: sliderIcon4, 
        title: 'RAČUNARI I OPREMA',
        description: 'Profesionalno projektovanje računarskih konfiguracija široke namjene',
        btnLink: 'asd3',
    },*/
    {
        src: picture4,
        sliderIcon: sliderIcon3, 
        title: 'EVIDENCIJA RADNIKA',
        description: 'Možemo Vam ponuditi izradu programa za kadrovsku evidenciju koji će Vam omogućiti...',
        btnLink: '/evidencijaRadnika',
    },
    {
        src: picture5,
      
        title: 'POS OPREMA',
        description: 'Možemo vam ponuditi kompletne i fleksibilne POS usluge, od izbora POS terminala do razvoja i integracije softvera.',
        btnLink: '#',
    },
    {
        src: picture6,
        sliderIcon: sliderIcon5, 
        title: 'FISKALNI UREĐAJI',
        description: 'Program za fiskalne uređaje  u maloprodaji i veleprodaji namijenjenjen je svim maloprodajnim i ...',
        btnLink: '/fiskalne',
    },
];


class carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }
    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        const { activeIndex } = this.state;

        const slides = items.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <Row noGutters>
                        <Col xl="5" lg="5" md="6" xs = "6"  className = "positionFix">
                            <div className="carouselText animated fadeIn">
                                <h2>{item.title}</h2>
                                <p>{item.description}</p>
                                <Link to={item.btnLink}><Button><span>DETALJNIJE</span></Button></Link>
                            </div>
                        </Col>
                        <Col xl = "2" lg = "2" className = "d-none d-md-none d-lg-block">
                            <img src={item.sliderIcon} className = "img-fluid animated bounceIn"/>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs = "6" className = "offset-xl-1 offset-lg-1">
                            <img src={item.src} alt={item.altText} className="img-fluid animated rotateIn" />
                        </Col>
                    </Row>

                </CarouselItem >
            );
        });
        return (
            <Container>
                <Carousel
                    activeIndex={activeIndex}
                    next={this.next}
                    previous={this.previous}
                >
                    <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                    {slides}
           
                </Carousel>
            </Container >
        )
    }
}

export default carousel
