import React, { Component } from 'react'
import DefaultLayout from '../../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import webDesignImage from '../../images/web/vpn.jpg';
import { Container, Row, Col } from 'reactstrap';
import Partners from '../../containers/partners';
import Map from '../../containers/map.js';
export class vpn extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="aboutPage">

                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>VPN</h1>


                        <p><Link to="/">Početna</Link> / VPN</p>
                    </div>
                    <div className="content">
                        <h1>BSS SOLUTIONS</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <img src={webDesignImage} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <p>

                                    VPN mreža predstavlja jedno od rješenja kako da povećate ličnu sigurnost i anonimnost na internetu.
                                    Ne možemo a da se ne zapitamo koliko je komunikacija sa udaljenim uređajem kojeg koristite sigurna i  pouzdana.
                                     Visok kvalitet propusnosti, stabilnost i sigurnost podataka odlike su mreže vrhunskih performansi.
                                     Komunikacija svih poslovnih aplikacija i sistema zavisi od  performansi mreže.
                                    
                                    
                               </p>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <p> Osim visokog kvaliteta propusnosti i stabilnosti mreže radi nesmetanog poslovanja, od izuzetnog značaja je sigurnost poslovnih podataka koji prolaze kroz tu mrežu. VPN uslugom osiguravamo zahtjeve klijenata, kao što su upravljanje IP adresama, mehanizmi upravljanja, podršku za razne protokole, pravo pristupa, autentikacija i autorizacija, integritet podataka,povjerljivost (tajnost, kriptiranje).</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="partnersAboutWrapper">
                    <Partners />
                </div>

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>

            </div>
        )
    }
}

export default DefaultLayout(vpn)
