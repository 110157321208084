import React, { Component } from 'react'
import DefaultLayout from '../../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import webDesignImage from '../../images/web/webapp.png';
import { Container, Row, Col } from 'reactstrap';
import Partners from '../../containers/partners';
import Map from '../../containers/map.js';
export class webApp extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="aboutPage">

                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>Web Aplikacije</h1>


                        <p><Link to="/">Početna</Link> / Web Aplikacije</p>
                    </div>
                    <div className="content">
                        <h1>BSS SOLUTIONS</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <img src={webDesignImage} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <p>
                                    Pružamo Vam uslugu programiranja i izrade web aplikacija u skladu sa Vašim potrebama i željama, a koje su prilagođene desktop računaru, laptopu , mobilnom telefonu ili tabletu. Mobilnost web aplikacija je njihova najveća prednost i prevazilaze klasične desktop aplikacije, upravo iz razloga jer su u svakom momentu dostupne sa bilo kojeg uređaja i njihovo ažuriranje je lakše. Pravi odabir web aplikacije u konsultaciji sa našim programerima u znatnoj mjeri će pojednostaviti i ubrzati Vaše poslovanje.
    
                               </p>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <p></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="partnersAboutWrapper">
                    <Partners />
                </div>

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>

            </div>
        )
    }
}

export default DefaultLayout(webApp)
