import React from 'react';
import Navigacija from './navbar.js';
import Footer from './footer.js';


export const DefaultLayout = (Wrapped) => (props) => {
        return(
            <div>
                <Navigacija {...props}  home={props[0].location.pathname == "/" ? true : false} />
                <Wrapped {...props} />
                <Footer {...props}/>
            </div>
        )
}

export default DefaultLayout;