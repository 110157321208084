import React, { Component } from 'react'
import abc from '../images/abc.png';
import hl from '../images/hladnevode.png';
import dado from '../images/dado.png';
import sig from '../images/sig.png';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from 'react-router-dom'


import {
    Container, Row, Col, Button
} from 'reactstrap';

export class partners extends Component {
    render() {
        return (
            <div className="partners">

                <Container>
                    <Row>
                        <Col xl="6" lg="6" md="6">
                            <h1>NAŠI PARTNERI</h1>
                            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <p>Naša saradnja sa partnerima zasniva se na pružanju kvalitetnih rješenja za njihovo poslovanje, koja se kontinuirano koriste na obostrano zadovoljstvo. Svojim klijentima donosimo napredne tehnologije na polju razvoja, kao i savremene trendove i alate.</p>
                                    <Link to = "/reference"><Button><span>DETALJNIJE</span></Button></Link>

                            </ScrollAnimation>

                        </Col>
                        <Col xl="6" lg="6" md="6">
                            <div className="wrapper">
                                <Row >
                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                        <div className="boxWrapper">
                                            <img src={hl} className="img-fluid" />
                                        </div>
                                    </ScrollAnimation>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                        <div className="boxWrapper">
                                            <img src={abc} className="img-fluid" />
                                        </div>
                                    </ScrollAnimation>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                        <div className="boxWrapper">
                                            <img src={sig} className="img-fluid" />
                                        </div>
                                    </ScrollAnimation>
                                    </Col>
                                    <Col xl="6" lg="6" md="6" xs="6">
                                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                                        <div className="boxWrapper">
                                            <img src={dado} className="img-fluid" />
                                        </div>
                                    </ScrollAnimation>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>
        )
    }
}

export default partners
