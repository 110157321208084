import React, { Component } from 'react'
import DefaultLayout from '../../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import webDesignImage from '../../images/web/webHost.jpg';
import { Container, Row, Col } from 'reactstrap';
import Partners from '../../containers/partners';
import Map from '../../containers/map.js';
export class webHost extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="aboutPage">

                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>Web Host</h1>


                        <p><Link to="/">Početna</Link> / Web Hosting</p>
                    </div>
                    <div className="content">
                        <h1>BSS SOLUTIONS</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <img src={webDesignImage} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <p>
                                    Da bi Vaš web sajt bio vidljiv na internetu, našim klijentima obezbjeđujemo usluge koje podrazumijevaju web hosting i domen, odnosno smještaj za određeni internet sajt na globalnoj mreži.
    
                               </p>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <p></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="partnersAboutWrapper">
                    <Partners />
                </div>

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>

            </div>
        )
    }
}

export default DefaultLayout(webHost)
