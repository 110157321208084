import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout.js';
import BSSicon from '../images/BSSicon.png';
import Strategy from '../containers/strategy.js';
import { Container, Row, Col } from 'reactstrap';
import Map from '../containers/map.js';

export class evidencijaRadnika extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="evidencijaRadnika">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>SOFTWARE</h1>


                        <p><Link to="/">Početna</Link> / Software</p>
                    </div>

                    <div className="content">
                        <h1>EVIDENCIJA RADNIKA</h1>
                        <Row>
                            <Col xl="8" lg="7" md="7">
                                <p>Možemo Vam ponuditi izradu programa za kadrovsku evidenciju koji će Vam omogućiti administraciju osnovnih podataka o zaposlenim, vođenje evidencije odsustava, hronološko praćenje svih promjena, vođenje evidencije o radnom vremenu i još mnogo toga.
        Evidencija radnog vremena je program namijenjen registraciji, kontroli, analizi i upravljanju radnim vremenom zaposlenih, a podržava:
        </p>
        <p>•	Bilježenje dolazaka i odlazaka korištenjem fleksibilne i skalabilne identifikacijske strukture. </p>
                                <p>•	Održavanje matičnih podataka zaposlenih </p>
                                <p>•	Praćenje pauze, privatnih i službenih izlazaka</p>
                                <p>•	Evidencija radnog vremena, bolovanja, godišnjih odmora i slobodnih dana zaposlenih</p>
                                <p>•	Planiranje i kontrola utroška godišnjih odmora i slobodnih dana, praćenje promjena podataka zaposlenih u pogledu njihovog statusa, prava iz radnog odnosa, organizacijskih i ostalih promjena u okviru firme i slično</p>
                                <p>•	Upoređivanje odrađenih vremena sa normativima</p>
                                <p>•	Konfigurabilan prikaz prekovremenog rada i noćnog rada zaposlenih prema pravilniku firme</p>
                                <p>•	Praćenje promjena podataka zaposlenih u pogledu njihovog statusa, prava iz radnog odnosa, isplata, godišnjih odmora, organizacijskih i ostalih promjena u okviru firme i slično</p>
                                <p>•	Sistem izvještavanja koji će pružiti kvalitetni uvid kako u sve relevantne kadrovske promjene, tako i u troškove rada zaposlenih i honorarnih radnika
                                </p>
                                    </Col>
                            <Col xl="4" lg="4" md="4">
                                <div className="slika">
                                    <img src={BSSicon} className="img-fluid" />
                                </div>
                            </Col>
                            
                        </Row>

                    </div>
                </Container>
                <div className="wrappStrategy">
                    <Strategy />
                </div>
                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(evidencijaRadnika)
