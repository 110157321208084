import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout.js';
import Partners from '../containers/partners.js';
import BSSicon from '../images/BSSicon.png';
import Map from '../containers/map.js';
import Hands from '../containers/hands.js';
import { Container, Row, Col, Button } from 'reactstrap';

export class knjigovodjstvoVeleprodaja extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;
      
        return (
            <div className="knjigovodjstvo">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>SOFTWARE</h1>


                        <p><Link to="/">Početna</Link> / Software</p>
                    </div>
                    <div className="content">
                        <h1>KNJIGOVODSTVO</h1>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="slika">
                                <img src={BSSicon} className="img-fluid" />
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="7" xs="12" className="offset-xl-1 offset-lg-1 ">
                                <h6>Veleprodaja</h6>
                                <p>Program za veleprodaju je jednostavan za upotrebu, a obuhvata sve dokumente i izvještaje koje su potrebni u trgovini na veliko.
Automatizuje i ubrzava evidenciju prijema i izdavanja robe, izradu ponuda, predračuna, računa i drugih dokumenata. Program automatski pravi važne izvještaje kao što su kartice kupaca, lager lista, promet robe po kupcima, knjiga primljenih računa. 

                                </p>
                            </Col>
                            <Col xl="12">
                                <p>Program za veleprodaju funkcioniše po sistemu server/klijent što znači da su svi podaci centralizovani na jedno mesto čime je pojednostavljen rad i postavljena osnova za efikasno izveštavanje i analizu. Omogućen je i pristup podacima sa udaljene lokacije. 
Program podržava robno knjigovodstvo,tj. robni promet, fakturisanje, blagajnu i finansijski promet, kreiranje i ispis izvještaja koji prate radi promjene u veleprodaji.
  </p>
                            </Col>
                        </Row>

                    </div>
                </Container>
                <Hands/>
                <Partners />

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(knjigovodjstvoVeleprodaja)
