import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import Slider from "react-slick";
import {Link} from 'react-router-dom';


import slider1 from '../../images/slickSlider/slider1.png';
import slider2 from '../../images/slickSlider/slider2.png';
import slider3 from '../../images/slickSlider/slider3.png';
import slider4 from '../../images/slickSlider/pos-oprema.jpg';

import {
    Container, Row, Col, Button
} from 'reactstrap';

export class services extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (

            <>
       <Container>
                        <div className="slider">
                            <h1>Usluge koje pružamo</h1>
                            <Slider {...settings}>
                                <div className="box">
                                    <img src={slider1} className="img-fluid" />
                                    <div className="text">
                                   <h5>SOFTWARE</h5>
                                   <p>Klijentima nudimo softverska  rješenja koja podržavaju finansijsko knjigovodstvo i robno-materijalno knjigovodstvo, kao i evidenciju zaposlenih. 
Knjigovodstveni program značajno skraćuje vrijeme potrebno za knjiženje isprava tako što u najvećoj mogućoj mjeri automatizuje postupak knjiženja i formiranja dokumenata. </p>
                                    <Button><span>DETALJNIJE</span></Button>
                                    </div>
                                </div>
                                <div className="box">
                                    <img src={slider2} className="img-fluid" />
                                    <div className="text">
                                    <h5>FISKALNE KASE</h5>
                                    <p>Sve obaveze oko fiskalizacije završavamo za Vas u što kraćem roku. Preuzimate uređaj spreman za rad sa unesenom bazom artikala. Obezbjeđena Vam je adekvatna obuka na našem testnom uređaju uz uputstva za rad. U ponudi imamo veliki izbor fiskalnih kasa. </p>
                                 <Link to="/fiskalne"><Button><span>DETALJNIJE</span></Button></Link>

                                    </div>
                                </div>
                                <div className="box">
                                    <img src={slider3} className="img-fluid" />
                                    <div className="text">
                                    <h5>WEB RJEŠENJA</h5>
                                    <p>Naš tim za web rješenja izvrsno radi na projektima koji nude zanimljiv dizajn, optimalnu fleksibilnost i jednostavnost upotrebe. Uz elegantne performanse na svim platformama, možete efikasno privući svoju publiku i izgraditi svoj brend.

</p>
                                   <Link to="#"> <Button><span>DETALJNIJE</span></Button></Link>

                                    </div>
                                </div>
                                <div className="box">
                                    <img src={slider4} className="img-fluid" />
                                    <div className="text">
                                    <h5>POS OPREMA</h5>
                                    <p>Cras sit amet bibendum odio, sit 
                                    amet hendrerit dui. Aenean laoreet 
                                    condimentum mattis.  Cras sit amet 
                                    bibendum odio, sit amet hendrerit 
                                    dui. Aenean laoreet condimentum </p>
                                    <Link to="/posOprema"><Button><span>DETALJNIJE</span></Button></Link>

                                    </div>
                                </div>

                            </Slider>
                        </div>
                       
                </Container>

            </>

        )
    }
}

export default services
