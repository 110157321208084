import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../../containers/defayltLayout.js';
import { Container, Row, Col, Table } from 'reactstrap';
export class cjenovnik2 extends Component {
	render() {
		return (
			<div>
				<div className="knjigovodjstvoAzure">
					<Container>
						<div className="bredcrumb d-flex justify-content-between">
							<h1>SOFTWARE</h1>


							<p><Link to="/">Početna</Link> / Cjenovnik</p>
						</div>
						<div className="content">

							<h1>CJENOVNIK</h1>

							<Row>
								<Col xl="12" xs="12" md="12" lg="12">
									<Table>
										<thead>
											<tr className="titleTable">
												<th>Modul</th>
												<th>Cijena</th>
												<th>PDV</th>
											</tr>
											<tr className="contentFirst">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – VP  OSNOVNI</th>
												<td>od 1.200,00 KM</td>
												<td>1.404,00 KM</td>
											</tr>
											<tr className="contentSec">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – VP PROŠIRENI<br />
													(automatske narudžbe, opširne analize)</th>
												<td>od 2.000,00 KM</td>
												<td>2.340,00 KM</td>
											</tr>
											<tr className="contentFirst">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – VP NAPREDNI<br />
													(autom.narudžbe, analize, planovi, podsjetnici, crm)</th>
												<td>od 3.000,00 KM</td>
												<td>3.510,00 KM</td>
											</tr>
											<tr className="contentSec">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – MP OSNOVNI</th>
												<td>od 1000,00 KM</td>
												<td>1170,00 KM</td>
											</tr>


											<tr className="contentFirst">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – MP i VP</th>
												<td>od 1.500,00 KM</td>
												<td>1.755,00 KM</td>
											</tr>

											<tr className="contentSec">
												<th>FINANSIJE</th>
												<td>od 1000,00 KM</td>
												<td>1170,00 KM</td>
											</tr>

											<tr className="contentFirst">
												<th>OSNOVNA SREDSTVA</th>
												<td>od 1000,00 KM</td>
												<td>1170,00 KM</td>
											</tr>

											<tr className="contentSec">
												<th>PLATE</th>
												<td>od 1000,00 KM</td>
												<td>1170,00 KM</td>
											</tr>

											<tr className="contentFirst">
												<th>ROBNO I MATERIJALNO KNJIGOVODSTVO – PROIZVODNJA</th>
												<td>od 1.000,00 KM</td>
												<td>1.170,00 KM</td>
											</tr>

											<tr className="contentSec">
												<th>VOĐENJE SERVISA</th>
												<td>od 700,00 KM</td>
												<td>819,00 KM</td>
											</tr>

											<tr className="contentFirst">
												<th>PRAĆENJE CARINSKOG SKLADIŠTA – LOHN POSLOVI</th>
												<td>od 5.000,00 KM</td>
												<td>5.850,00 KM</td>
											</tr>

											<tr className="contentSec">
												<th>FINANSIJE, PLATE, OSNOVNA SREDSTVA, <br />
													ROBNO I MATERIJALNO KNJIGOVODSTVO, PROIZVODNJA</th>
												<td>od 4.500,00 KM</td>
												<td>5.265,00 KM</td>
											</tr>

											<tr className="contentFirst">
												<th>FINANSIJE, PLATE, OSNOVNA SREDSTVA,<br />
													ROBNO I MATERIJALNO KNJIGOVODSTVO</th>
												<td>od 3.000,00 KM</td>
												<td>3.510,00 KM</td>
											</tr>

											<tr className="contentSec">
												<th>PARTNERI</th>
												<td>od 300,00 KM</td>
												<td>351,00 KM</td>
											</tr>

											<tr className="contentFirst">
												<th>RESTORANSKO POSLOVANJE</th>
												<td>od 1.800,00 KM</td>
												<td>2.106,00 KM</td>
											</tr>



										</thead>
									</Table>
								</Col>
							</Row>
							<Row>
								<Col xl="12" xs="12" md="12" lg="12">
									<div className="textDecs">
										<h4>Cjenovnik Usluga</h4>
										<p>U sljedećoj tabeli prikazane su cijene usluga za klijente koji  nisu VIP korisnici ili VIP Partneri, odnosno nemaju ugovor o održavanju. Cijene su izražene u Konvertibilnim Markama, na koje se obračunava PDV ( stopa 17%):</p>
									</div>
								</Col>

								<Col  xl="12" xs="12" md="12" lg="12">
									<Table borderless className="Tabelaaa">
										<thead> 
										<tr className="titleTable">
											<th>Opis usluge</th>
											<th>Jedinica mjere</th>
											<th>Prva intervencija</th>
											<th>Dodatna intervencija</th>
											<th>Intervencija u dane vikenda ili praznika</th>
										</tr>
										
										<tr className="contentTable3">
											<th>Robno knjigovodjstvo <br/>
											Započeti sat - remote podrška (Team Viewer;VNC) 
											</th>
											<td>SAT</td>
											<td>35,00</td>
											<td>25,00</td>
											<td>65,00</td>
										
										</tr>
										<tr className="contentTable3">
											<th>Intervencija - remote podrška (Team Viewer;VNC)</th>
											<td></td>
											<td>50,00</td>
											<td>35,00</td>
											<td>75,00</td>
										</tr>
										<tr className="contentTable3">
											<th>Hitna intervencija - remote podrška (Team Viewer;VNC)</th>
											<td></td>
											<td>70,00</td>
											<td>50,00</td>
											<td>90,00</td>
										</tr>

										<tr className="contentTable32">
											<th>Finansije<br/>
											Započeti sat - remote podrška (Team Viewer;VNC)</th>
											<td>SAT</td>
											<td>70,00</td>
											<td>35,00</td>
											<td>80,00</td>
										</tr>
										<tr className="contentTable32">
											<th>Intervencija - remote podrška (Team Viewer;VNC)</th>
											<td></td>
											<td>50,00</td>
											<td>45,00</td>
											<td>90,00</td>
											
										</tr>
										<tr className="contentTable32">
											<th>Hitna intervencija - remote podrška (Team Viewer;VNC)</th>
											<td></td>
											<td>70,00</td>
											<td>60,00</td>
											<td>200,00</td>
										</tr>
										<tr className="contentTable3">
											<th>Dodatne usluge<br/>
											Konsultacije - robno knjigovodjstvo</th>
											<td>SAT</td>
											<td>60,00</td>
											<td>40,00</td>
											<td>70,00</td>
										</tr>
										<tr className="contentTable3">
										<th>Konsultacije - finansije</th>
										<td></td>
										<td>80,00</td>
										<td>50,00</td>
										<td>80,00</td>
										</tr>

										<tr className="contentTable32">
										<th>Dodatne usluge, izlazak na teren<br/>
										Izlazak na lokaciju - udaljenost do 20 kilometara</th>

										<td>/km</td>
										<td>45,00</td>
										<td>35,00</td>
										<td>70,00</td>
										</tr>

										<tr className="contentTable32">
											<th>Izlazak na lokaciju, hitna intervencija udaljenost do 20 kilometara</th>
											<td></td>
											<td>55,00</td>
											<td>45,00</td>
											<td>80,00</td>
										</tr>
										<tr className="contentTable32">
											<th>Izlazak na lokaciju - udaljenost > 20 kilometara</th>
											<td></td>
											<td>0,60</td>
											<td>0,50</td>
											<td>1,20</td>
										</tr>
										<tr className="contentTable32">
											<th>Izlazak na lokaciju, hitna intervencija udaljenost > 20 kilometara</th>
											<td></td>
											<td>0,80</td>
											<td>0,60</td>
											<td>1,50</td>
										</tr>
										</thead>

										<tbody >
							
									
											
										</tbody>
									</Table>
								</Col>
							</Row>
							<Row>
								<Col  xl="12" xs="12" md="12" lg="12">
									<div className="textDecs">
										<h4>Cjenovnik Usluga</h4>
										<p>Licence podrške
		U mogućnosti smo da Vam ponudimo naše usluge, podijeljene na posebne pakete usluga i koje možete vidjeti u priloženoj tabeli.
		
		Odabirom željenog paketa usluga, takođe stičete status VIP korisnika, dok zaključivanjem ugovora o održavanju stičete status VIP partnera u kom slučaju smo u mogućnosti da Vam kreiramo posebne pakete usluga u okviru navedenih usluga, kao i posebne popuste i akcije na ostale proizvode iz našeg prodajnog takođe te  i proizvodnog asortimana.
		
		Ukoliko ipak ne odaberete neki od ponuđenih paketa usluga održavanja, nakon isteka perioda od 6 mjeseci za svaku intervenciju primjenjujemo opšte važeće tarife za naše usluge, a prema stavkama:
</p>
										<p>cijena po satu/utrošeno vrijeme; </p>
										<p>cijena po satu-teren/utrošeno vrijeme.</p>
									</div>
								</Col>
								<Col  xl="12" xs="12" md="12" lg="12">
									<Table className="Tabelaaa">
										<thead>
											<tr className="titleTable">
												<th>Opis usluge</th>
												<th>BRONZE</th>
												<th>SILVER</th>
												<th>GOLD</th>
												<th>PLATINUM</th>

											</tr>

											<tr className="contentTable2">
												<th>Start tarifa (uračunate tri licence)</th>
												<td>50,00</td>
												<td>80,00</td>
												<td>150,00</td>
												<td>300,00</td>
											</tr>
											<tr className="contentTable22">
												<th>Broj računara >3 (mjesečno po licenci)</th>
												<td>20,00</td>
												<td>25,00</td>
												<td>30,00</td>
												<td>40,00</td>
												
											</tr>
											<tr className="contentTable2">
												<th>Izlazak na teren po pozivu - po 1 lokaciji</th>
												<td>20,00</td>
												<td>15,00</td>
												<td>10,00</td>
												<td>URAČUNATO</td>
											</tr>
											<tr className="contentTable22">
												<th>Intervencija u roku od 2 časa</th>
												<td>X</td>
												<td>X</td>
												<td>X</td>
												<td>URAČUNATO</td>
											</tr>
											<tr className="contentTable2">
												<th>Intervencija na poziv do kraja radnog vremena ili do 12 časova narednog dana</th>
										
											<td>X</td>
											<td>PO  DOGOVORU</td>
											<td>URAČUNATO</td>
											<td>URAČUNATO</td>
											</tr>
											<tr className="contentTable22">
												<th>Intervencija u roku od 24 časa</th>
												<td>X</td>
												<td>URAČUNATO</td>
												<td>URAČUNATO</td>
												<td>URAČUNATO</td>
											</tr>
											<tr  className="contentTable2">
												<th>Uračunate nove verzije programa i otvaranje nove godine</th>
												<td>PO DOGOVORU</td>
												<td>URAČUNATO</td>
												<td>URAČUNATO</td>
												<td>URAČUNATO</td>
											</tr>
											<tr  className="contentTable22">
												<th>Razvoj prema zahtjevu kupca</th>
												<td>PO DOGOVORU</td>
												<td>PO DOGOVORU</td>
												<td>PO DOGOVORU</td>
												<td>URAČUNATO</td>
											</tr>
										</thead>

										
									</Table>
								</Col>
							</Row>


						</div>
					</Container>
				</div>
			</div>
		)
	}
}

export default DefayltLayout(cjenovnik2)
