import React, { Component } from 'react'
import DefaultLayout from '../../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import webDesignImage from '../../images/web/webDesign.jpg';
import { Container, Row, Col } from 'reactstrap';
import Partners from '../../containers/partners';
import Map from '../../containers/map.js';
export class webDesign extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const lang = 44.625690;
        const lat = 17.373042;
  
        return (
            <div className="aboutPage">

                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>Web Dizajn</h1>


                        <p><Link to="/">Početna</Link> / Web Dizajn</p>
                    </div>
                    <div className="content">
                        <h1>BSS SOLUTIONS</h1>

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <img src={webDesignImage} className="img-fluid" />
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <p>
                                    Profesionalno i kvalitetno radimo usluge dizajniranja web stranica i uz kreativan pristup  i na moderan način prikazaćemo Vaše poslovanje na internetu.
                                    Dobro dizajnirana, originalna, zanimljiva  ili interaktivna web stranica povećava mogućnost da uspostavite kontakt sa većim brojem Vaših klijenata, kupaca i korisnika Vaših usluga, koji će Vam se uvijek vraćati, a mi smo tu da Vam pomognemo i ponudimo najbolje rješenje.
                                    Takođe nudimo i uslugu održavanja web sttranica u skladu sa Vašim zahtjevima.
                                    
                               </p>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <p></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="partnersAboutWrapper">
                    <Partners />
                </div>

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>

            </div>
        )
    }
}

export default DefaultLayout(webDesign)
