import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import Slider from "react-slick";
import blueWallpaper from '../../images/blue-wallpaper.png';

import slider1 from '../../images/slickSlider/slide1.png';
import slider2 from '../../images/slickSlider/slide2.png';
import slider3 from '../../images/slickSlider/slide3.png';
import slider4 from '../../images/slickSlider/fiskalna.jpg';

import check from '../../images/slickSlider/check.svg';

import {
    Container, Row, Col, Button
} from 'reactstrap';

export class priceList extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };
        return (

            <>
                <div className="slider2">
                    <Container>

                        <h1>Naš cjenovnik fiskalnih kasa</h1>
                        <Slider {...settings}>
                            <div className="box">
                                <img src={slider3} className="img-fluid" />
                                <h2>BSS FISCAL BASIC</h2>
                                <h3>10.00 KM</h3>
                                <ul>
                                    <li><Isvg src={check}/>Vrijeme odziva na paziv 48h</li>
                                    <li><Isvg src={check}/>Telefonska podrška</li>
                                    <li><Isvg src={check}/>Dolazak po pozivu</li>
                                    <li><Isvg src={check}/>Mjesečna kontrola </li>
                                    <li><Isvg src={check}/>Plaćanje preko računa </li>

                                </ul>
                                <Button className="universalBtn"><span>DETALJNIJE</span></Button>

                            </div>
                            <div className="box">
                                <img src={slider2} className="img-fluid" />
                                <h2>BSS FISCAL STANDARD</h2>
                                <h3>20.00 KM</h3>
                                <ul>
                                    <li><Isvg src={check}/>Vrijeme odziva na poziv 24h</li>
                                    <li><Isvg src={check}/>Telefonska podrška</li>
                                    <li><Isvg src={check}/>Dolazak po pozivu</li>
                                    <li><Isvg src={check}/>Mjesečna kontrola </li>
                                    <li><Isvg src={check}/>Plaćanje preko računa </li>
                                </ul>
                                <Button className="universalBtn"><span>DETALJNIJE</span></Button>


                            </div>
                            <div className="box">
                                <img src={slider1} className="img-fluid" />
                                <h2>BSS FISCAL PREMIUM</h2>
                                <h3>30.00 KM</h3>
                                <ul>
                                    <li><Isvg src={check}/>Vrijeme odziva na poziv 12h</li>
                                    <li><Isvg src={check}/>Telefonska podrška</li>
                                    <li><Isvg src={check}/>Dolazak po pozivu</li>
                                    <li><Isvg src={check}/>Mjesečna kontrola </li>
                                    <li><Isvg src={check}/>Plaćanje preko računa </li>
                                </ul>
                                <Button className="universalBtn"><span>DETALJNIJE</span></Button>


                            </div>
                            <div className="box">
                                <img src={slider4} className="img-fluid" />
                                <h2>BSS FISCAL PLATINUM</h2>
                                <h3>25.00 KM</h3>
                                <ul>
                                    <li><Isvg src={check}/>Vrijeme odziva na poziv 24h</li>
                                    <li><Isvg src={check}/>Telefonska podrška</li>
                                    <li><Isvg src={check}/>Dolazak po pozivu</li>
                                    <li><Isvg src={check}/>Mjesečna kontrola </li>
                                    <li><Isvg src={check}/>Plaćanje preko računa </li>
                                </ul>
                                <Button className="universalBtn"><span>DETALJNIJE</span></Button>


                            </div>

                        </Slider>


                    </Container>
                </div>

            </>

        )
    }
}

export default priceList
