import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import logo2 from '../images/logo2.svg';
import ScrollAnimation from 'react-animate-on-scroll';

import {
    Col, Container, Row, Button
} from 'reactstrap';
export class trialVersion extends Component {
    render() {
        return (
            <div className="trialVersion">
                <Container>
                    <Row>
                        <Col xl="3" lg="3" md="3" className = "d-none d-md-block d-lg-block">
                            <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                                <Isvg src={logo2} />
                            </ScrollAnimation>
                        </Col>
                        <Col xl="8" lg="8" md="8" className="offset-xl-1">
                                <h1>Probna verzija</h1>
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>

                                <p>Naše dugogodišnje iskustvo u pružanju softverskih rješenja u značajnoj mjeri utiče na poboljšanje Vašeg poslovanja. Bavimo se razvojem softvera za potrebe klijenata, kompleksnih web sajtova i web aplikacija koje se integrišu u postojeće poslovne procese naših klijenata. Nudimo izradu programa za finansijsko knjigovodstvo i robno-materijalno knjigovodstvo, kao i evidenciju zaposlenih i izradu ostalih programa u zavisnosti od potreba. </p>
            	                <div className = "buttons">
                                <Button><span>PROBNA VERZIJA</span></Button>
                                <Button><span>POTPUNA VERZIJA</span></Button>
                                </div>
                            </ScrollAnimation>
                        </Col>

                    </Row>
                </Container>

            </div>
        )
    }
}

export default trialVersion
