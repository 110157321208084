import React, { Component } from 'react'
import DefayltLayout from '../containers/defayltLayout.js';
import { Link } from 'react-router-dom';
import abc from '../images/reference/abc.png';
import ograde from '../images/reference/ograde.png';
import vode from '../images/reference/vode.png';
import moma from '../images/reference/moma.png';
import ftf from '../images/reference/ftf.png';
import dado from '../images/reference/dado.png';
import prevoz from '../images/reference/prevoz.png';
import sigurnost from '../images/reference/sigurnost.png';
import ScrollAnimation from 'react-animate-on-scroll';

import {
    Container, Row, Col
} from 'reactstrap';


export class reference extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div className="reference">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>REFERENCE</h1>
                        <p><Link to="/">Početna</Link> / Reference</p>
                    </div>

                    <div className="content">
                        <h1>Naši klijenti</h1>
                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="box">
                                        <img src={abc} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>

                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <p> Više na  <a href="https://www.abcsporting.com/" > ABC SPORTING </a></p>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <p> Više na  <a href="https://www.mehanickekonstrukcije.com/" > MEHANIČKE KONSTRUKCIJE </a></p>
                               
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <img src={ograde} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="box">
                                        <img src={vode} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <p> Više na  <a href="https://hajduckevode.biz/" > HAJDUČKE VODE </a></p>
                                    <p>


                                </p>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <p> Više na  <a href="http://www.autoservis-moma.com/" > MOMA AUTO SERVIS </a></p>

                                    <p>

                                </p>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <img src={moma} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="box">
                                        <img src={prevoz} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <p>Prevoz Golub - Golubic
                                </p>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                <p> Više na  <a href="http://www.sp-sigurnost.com/" > Sigurnost - proizvodnja termoizolacionog stakla </a></p>


                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <img src={sigurnost} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <div className="box">
                                        <img src={dado} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                <p> Više na  <a href="http://hadziric-dado.com/" > Dado komerce doo </a></p>

                                 
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12" className = "d-none d-md-block d-lg-block">
                                <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
                                    <p>F.T.F. d.o.o. Kotor Varoš
                                </p>
                                </ScrollAnimation>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
                                    <div className="box">
                                        <img src={ftf} className="img-fluid" />
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col xl = "12" md = "12" xs = "12" className = "mb-5">
                                <p>Kompletnu listu naših klijenata možete pogledati na sledećem <a href="/">linku.</a></p>
                            </Col>
                        </Row>
                    </div>

                </Container>


            </div>
        )
    }
}

export default DefayltLayout(reference)
