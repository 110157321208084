import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefayltLayout from '../containers/defayltLayout.js';
import Partners from '../containers/partners.js';
import BSSicon from '../images/BSSicon.png';
import Map from '../containers/map.js';
import Hands from '../containers/hands.js';
import { Container, Row, Col, Button } from 'reactstrap';

export class knjigovodjstvoMaloProdaja extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;
   
        return (
            <div className="knjigovodjstvo">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>SOFTWARE</h1>


                        <p><Link to="/">Početna</Link> / Software</p>
                    </div>
                    <div className="content">
                        <h1>KNJIGOVODSTVO</h1>
                        <Row>
                            <Col xl="4" lg="4" md="4" xs="12">
                                <div className="slika">
                                <img src={BSSicon} className="img-fluid" />
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="7" xs="12" className="offset-xl-1 offset-lg-1 ">
                                <h6>Maloprodaja</h6>
                                <p>Nudimo kompletno rješenje namijenjeno maloprodajnim objektima. Program je prilagođen za rad sa većinom fiskalnih štampača i kasa. Program omogućava vođenje precizne evidencije o prometu robe, prihodima i rashodima. Značajno olakšava operacije koje se u maloprodaji obavljaju svakodnevno, naročito izdavanje računa, izradu kalkulacija, evidenciju povrata robe i slično. Automatski formira izvještaje kao što su lager lista, promet kase. Program za maloprodaju funkcioniše po sistemu server/klijent, što znači da su svi podaci centralizovani na jednom mestu. Omogućen je i pristup podacima sa udaljene lokacije. Program podržava rad sa više maloprodajnih objekata koji posluju u okviru istog pravnog lica. Može da se koristi samostalno ili zajedno sa programom za veleprodaju.


                                </p>
                            </Col>
                            <Col xl="12">
                                <p>Program podržava robno knjigovodstvo,tj. robni promet, fakturisanje, blagajnu i finansijski promet, kreiranje i ispis izvještaja koji prate radi i promjene u maloprodaji.</p>
                            </Col>
                        </Row>

                    </div>
                </Container>
                <Hands/>
                <Partners />

                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(knjigovodjstvoMaloProdaja)
