import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, HashRouter } from "react-router-dom";

import Home from './views/home.js';
import About from './views/about.js';
import Reference from './views/reference.js';
import Contact from './views/contact'
import Fiskalne from './views/fiskalne';
import KnjigovodjstvoMaloProdaja from './views/knjigovodjstvoMaloProdaja.js';
import KnjigovodjstvoVeleprodaja from './views/knjigovodjstvoVeleprodaja.js';
import KnjigovodjstvoProizvodnja from './views/knjigovodjstvoProizvodnja.js';

import './App.css';
import './animate.css';
import GoogleMap from './googleMapScript.js';
import EvidencijaRadnika from './views/evidencijaRadnika.js';
import ScrollIntoView from './scrollIntoView';
import PosOprema from './views/posoperma.js';
import WebDesign from './views/web/webDesign.js';
import WebApp from './views/web/webApp.js';
import WebHost from './views/web/webHost.js';
import AudioHost from './views/web/audioHost.js';
import Vpn from './views/web/vpn.js';
import ProjectM from './views/web/projectM.js';
import Cjenovnik2 from './views/azzure/cjenovnik2.js';
class App extends React.Component {

  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.state = {};


  }

  callback() {
    this.setState({ mapinit: true });
  }

  render() {
    return (
      <div>
        <GoogleMap API_KEY="AIzaSyDtvBFupBq2iYpoUQuj_UdUduapj97_m7M" />

        <HashRouter>
          <ScrollIntoView>
            <Switch>
              <Route exact path="/" render={(...renderProps) => (<Home  {...renderProps} {...this.state} ></Home>)} />
              <Route exact path="/about" render={(...renderProps) => (<About {...renderProps} {...this.state}></About>)} />
              <Route exact path="/reference" render={(...renderProps) => (<Reference {...renderProps} {...this.state}/>)} />
              <Route exact path="/kontakt" render={(...renderProps) => (<Contact {...renderProps} {...this.state} />)} />
              <Route exact path="/fiskalne" render={(...renderProps) => (<Fiskalne {...renderProps} {...this.state} />)} />
              <Route exact path="/evidencijaRadnika" render={(...renderProps) => (<EvidencijaRadnika {...renderProps} {...this.state} />)} />
              <Route exact path="/knjigovodjstvoMaloProdaja" render={(...renderProps) => (<KnjigovodjstvoMaloProdaja {...renderProps} {...this.state} />)} />
              <Route exact path="/knjigovodjstvoVeleprodaja" render={(...renderProps) => (<KnjigovodjstvoVeleprodaja {...renderProps} {...this.state} />)} />
              <Route exact path="/knjigovodjstvoProizvodnja" render={(...renderProps) => (<KnjigovodjstvoProizvodnja {...renderProps} {...this.state} />)} />
          
              <Route exact path="/posOprema" render={(...renderProps) => (<PosOprema {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/webDesign" render={(...renderProps) => (<WebDesign {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/webApp" render={(...renderProps) => (<WebApp {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/webHost" render={(...renderProps) => (<WebHost {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/audioHost" render={(...renderProps) => (<AudioHost {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/vpn" render={(...renderProps) => (<Vpn {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/projectM" render={(...renderProps) => (<ProjectM {...renderProps} {...this.state}/>)}/>  
              <Route exact path="/cjenovnik" render={(...renderProps) => (<Cjenovnik2 {...renderProps} {...this.state}/>)}/>  
            </Switch>
          </ScrollIntoView>
        </HashRouter>
      </div>
    )
  }
}

export default App;
