import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import DefayltLayout from '../containers/defayltLayout.js';
import email from '../images/svg/email.svg';
import mobile from '../images/svg/mobile.svg';
import location from '../images/svg/location.svg';
import Map from '../containers/map.js';
import {
    Container, Row, Col, Button, FormGroup, Input, Textarea
} from 'reactstrap';

export class contact extends Component {
    render() {
        const lang = 44.625690;
        const lat = 17.373042;

        return (
            <div className="contact">
                <Container>
                    <div className="bredcrumb d-flex justify-content-between">
                        <h1>Kontakt</h1>


                        <p><Link to="/">Početna</Link> / Kontakt</p>
                    </div>


                    <div className="content">
                        <h1>Kontaktirajte nas</h1>
                        <p>Imate pitanja? Kontaktirajte nas i odgovorićemo Vam se u što kraćem roku.</p>

            

                        <Row>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Ime i Prezime" />
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <FormGroup>
                                    <Input type="text" placeholder="E-mail" />
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Naslov" />
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" xs="12">
                                <FormGroup>
                                    <Input type="text" placeholder="Broj telefona" />
                                </FormGroup>
                            </Col>
                            <Col xl="12" lg="12" md="12" xs="12">
                                <FormGroup>
                                    <Input type="textarea" placeholder="Naslov" />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Button><span>Pošalji</span></Button>
                    </div>


                    <Row>
                            <Col xl="12">
                                <div className="bibicSuport">
                                    <h4>Goran Bibić</h4>
                                    <h4>Tehnička podrška</h4>

                                    <div className="wrapSuport">
                                        <h6>PJ Banja Luka --- Petra Kočića 4, 78220 Kotor Varoš</h6>
                                        <h6>PJ Bijeljina --- Kruševačka 4b, Intergaj, 76300 Bijeljina</h6>
                                        <h6>PJ Široki Brijeg ---Fra Didaka Buntića 72, 88220 Široki Brijeg</h6>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    <div className="box d-block d-lg-flex justify-content-between">
                    
                        <a href="tel:[+38766444544]"><span class="bottom">    <p><Isvg src={mobile}/>+387 (0) 66 444 544</p></span></a>
                      
                        <a class="mailto" href="mailto:podrska@bss-solution.info">  <p><Isvg src={email}/> podrska@bss-solution.info</p>
</a>
                        

                        <a href="https://www.google.com/maps/dir//44.62569,17.373042/@44.6256663,17.3033499,12z">
                        <p><Isvg src={location}/>Petra Kočića 4, Kotor Varoš</p>
 </a>

                        
                    </div>
                </Container>
                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            </div>
        )
    }
}

export default DefayltLayout(contact)
