import React, { Component } from 'react'
import Isvg from 'react-inlinesvg';
import logo from '../images/logo.svg';
import { Link } from 'react-router-dom';
import arrowDown from '../images/svg/strelicaDole.svg';
import arrowLeft from '../images/svg/strelicaLijevo.svg';
import nav2 from '../images/nav2.png';
import arrowLeft2 from '../images/svg/arrow-left.svg';
import hamburger from '../images/svg/hamburger.svg';
import Close from '../images/svg/Close.svg';
import arrowRight from '../images/svg/arrowRight.svg';

import { NavLink as RRNavLink } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    Navbar,
    Collapse,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';

class Navigacija extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            width: 0,
            height: 0,
            isOpen: false,
            dropDownMenu: false,
            dropDownMenu2: false,
            otvoren: false, submenu: false, submenu1: false, submenu2: false, submenu3: false, submenu4: false, submenu5: false, submenu6: false, submenu7: false, submenu8: false


        };
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    funkcija(lokacije) {
        if (lokacije === "/") {
            this.setState({ submenu: true })
        }
        if (lokacije === "/evidencijaRadnika" || lokacije == "/cjenovnik") {
            this.setState({ submenu: false, submenu2: true })
        }
        if (lokacije == "/knjigovodjstvoMaloProdaja" || lokacije == "/knjigovodjstvoVeleprodaja" || lokacije == "/knjigovodjstvoProizvodnja") {
            this.setState({ submenu4: true })
        }
        if (lokacije == "/knjigovodjstvoMaloProdajaa" || lokacije == "/insurancePassengers" || lokacije == "/accidentInsurance" || lokacije == "/combinedInsurance" || lokacije == "/collectiveInsurance" || lokacije == "/studentsInsurance" || lokacije == "/guestInsurance" || lokacije == "/sportsinsurance") {
            this.setState({ submenu2: true })
        }
        if (lokacije == "/webDesign" || lokacije == "/webApp" || lokacije == "/webHost" || lokacije == "/audioHost" || lokacije == "/vpn" || lokacije == "/projectM") {
            this.setState({ submenu6: true })
        }
    }

    render() {
        const location = this.props[0].location.pathname;
        return (
          <div>
       {this.state.width > 991 ?
            <div className="navigacija">
                <Container>
                    <Row>
                        <Col lg="2" md="3" xs="4" style={{zIndex: 99}}>
                            <Isvg src={logo} />
                        </Col>
                     
                        <Col lg="9" md="9" xs="8" className="offset-lg-1 klasa">
                            <Navbar className="navbar navbar-expand-lg static-top">
                                <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i className="fa fa-bars"></i></button>
                                <Collapse isOpen={this.state.isOpen} navbar id="navbarResponsive">
                                    <Nav>
                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} to="/" activeClassName="active">
                                                <span>
                                                    POČETNA
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                 <a href="https://webshop.bss-solution.info/">
                                             
                                             WEBSHOP
                                    
                                         </a>
                                         </NavItem>
                                          
                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} to="/about" activeClassName="active">
                                                <span>
                                                    O NAMA
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret activeClassName="active" className="withArrow" style={{ padding: 0 }}>SOFTWARE</DropdownToggle>
                                            <DropdownMenu>
                                                <UncontrolledDropdown nav inNavbar>
                                                    <DropdownToggle to="#" className="dropdown-item">KNJIGOVODSTVO <Isvg src={arrowLeft} /></DropdownToggle>
                                                    <DropdownMenu>
                                                        <NavLink tag={RRNavLink} to="/knjigovodjstvoMaloProdaja" className="dropdown-item">MALOPRODAJA</NavLink>
                                                        <NavLink tag={RRNavLink} to="/knjigovodjstvoVeleprodaja" className="dropdown-item">VELEPRODAJA</NavLink>
                                                        <NavLink tag={RRNavLink} to="/knjigovodjstvoProizvodnja" className="dropdown-item">PROIZVODNJA</NavLink>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <Link to="/evidencijaRadnika" className="dropdown-item">EVIDENCIJA RADNIKA</Link>
                    
                                                 <NavLink tag={RRNavLink} to="/cjenovnik" className="dropdown-item">CJENOVNIK</NavLink>
    
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} to="/fiskalne" activeClassName="active">
                                                <span>
                                                    FISKALNE KASE
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret activeClassName="active" className="withArrow" style={{ padding: 0 }}>WEB RJEŠENJA</DropdownToggle>
                                            <DropdownMenu>
                                                <Link to="/webDesign"  className="dropdown-item">WEB DIZAJN</Link>
                                                <Link to="webApp" className="dropdown-item">WEB APLIKACIJE</Link>
                                                <Link to="/webHost" className="dropdown-item">WEB HOSTING</Link>
                                                <Link to="/audioHost" className="dropdown-item">AUDIO HOSTING</Link>
                                                <Link to="/vpn" className="dropdown-item">VPN MREŽE</Link>
                                                <Link to="/projectM" className="dropdown-item">PROJEKTOVANJE MREŽA</Link>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} to="/reference" activeClassName="active">
                                                <span>
                                                    REFERENCE
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink exact tag={RRNavLink} to="/kontakt" activeClassName="active">
                                                <span>
                                                    KONTAKT
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Collapse>
                            </Navbar>
                        </Col>
                    
                    </Row>
                </Container>
                <div className="bgc">
  <img src={nav2} className="img-fluid" />
</div>
            </div>

: <div className="wrapperNav2">

<Container >
    <Row>

        <Col xs="3" md="2" lg="2" style={{ zIndex: 1 }}>
            <div className="logo2">
                <Link to="/"><Isvg src={logo} /></Link>
            </div>
        </Col>
        <Col xs="8" md="9" lg="10" style={{ zIndex: 1 }}>
            <button className="btn-menu" onClick={() => this.setState({ otvoren: !this.state.otvoren }, this.funkcija(location))}><Isvg src={hamburger} /></button>
        </Col>
    </Row>
</Container>
<div className={this.state.otvoren ? 'navbarResposniveOpen2' : 'navbarResposnive2'}>
    <div className={this.state.otvoren ? 'sadrzajOpen2' : 'sadrzaj2'} >
        <button onClick={() => this.setState({ otvoren: !this.state.otvoren, submenu: false })} className="closeIcon"><Isvg src={Close}></Isvg></button>
        <div style={{ overflow: 'hidden' }}>
            <ul className={this.state.submenu ? "content" : "content"}>
                <li><Link to='/' className="nav-link">POČETNA</Link></li>
                <li><a href='https://webshop.bss-solution.info/' className="nav-link">WEBSHOP</a></li>


                <li> <Link to="/about" className={location === "/about" ? "nav-link active" : "nav-link"}>O NAMA</Link> </li>


        
                <button onClick={() => this.setState({ submenu2: !this.state.submenu2 })} className="arrowRight">SOFTWARE<Isvg src={arrowLeft2}></Isvg></button>
                <li> <Link to="/fiskalne" className={location === "/fiskalne" ? "nav-link active" : "nav-link"}>FISKALNE KASE</Link> </li>
                <button onClick={() => this.setState({ submenu6: !this.state.submenu6 })} className="arrowRight">WEB RIJEŠENJA<Isvg src={arrowLeft2}></Isvg></button>

                <li> <Link to="/reference" className={location === "/reference" ? "nav-link active" : "nav-link"}>REFERENCE</Link> </li>
                <li> <Link to="/kontakt" className={location === "/kontakt" ? "nav-link active" : "nav-link"}>KONTAKT</Link> </li>


         
               
               
            </ul>
        </div>




        <div style={{ overflow: 'hidden' }}>
            <ul className={this.state.submenu2 ? 'content2' : 'noContent2'}>
                <button onClick={() => this.setState({ submenu2: !this.state.submenu2, })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                <button onClick={() => this.setState({ submenu4: !this.state.submenu4 })} className="arrowRight">KNJIGOVODSTVO<Isvg src={arrowLeft2}></Isvg></button>                
                <li> <Link to="/evidencijaRadnika" className={location === "/evidencijaRadnika" ? "nav-link active" : "nav-link"}>EVIDENCIJA RADNIKA</Link> </li>
                <li> <Link to="/cjenovnik" className={location === "/cjenovnik" ? "nav-link active" : "nav-link"}>CJENOVNIK</Link> </li>

            </ul>
        </div>


        <div style={{ overflow: 'hidden' }}>
            <ul className={this.state.submenu4 ? 'content2' : 'noContent2'}>
                <button onClick={() => this.setState({ submenu4: !this.state.submenu4, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                <li><Link to="/knjigovodjstvoMaloProdaja" className={location === "/knjigovodjstvoMaloProdaja" ? "nav-link active" : "nav-link"}>MALOPRODAJA</Link></li>
                <li><Link to="/knjigovodjstvoVeleprodaja" className={location === "/knjigovodjstvoVeleprodaja" ? "nav-link active" : "nav-link"}>VELEPRODAJA</Link></li>
                <li><Link to="/knjigovodjstvoProizvodnja" className={location === "/knjigovodjstvoProizvodnja" ? "nav-link active" : "nav-link"}>PROIZVODNJA</Link></li>
            </ul>
        </div>






      


        <div style={{ overflow: 'hidden' }}>
            <ul className={this.state.submenu6 ? 'content2' : 'noContent2'}>
                <button onClick={() => this.setState({ submenu6: !this.state.submenu6, submenu: true })} className="arrowLeft"><Isvg src={arrowRight}></Isvg></button>
                <li><Link to='/webDesign' className={location === "/webDesign" ? "nav-link active" : "nav-link"}>WEB DIZAJN</Link></li>
                <li><Link to='/webApp' className={location === "/webApp" ? "nav-link active" : "nav-link"}>WEB APLIKACIJE</Link></li>
                <li><Link to='/webHost' className={location === "/webHost" ? "nav-link active" : "nav-link"}>WEB HOSTING</Link></li>
                <li><Link to='/audioHost' className={location === "/audioHost" ? "nav-link active" : "nav-link"}>AUDIO HOSTING</Link></li>
                <li><Link to='/vpn' className={location === "/vpn" ? "nav-link active" : "nav-link"}>VPN MREŽE</Link></li>
                <li><Link to='/projectM' className={location === "/projectM" ? "nav-link active" : "nav-link"}>PROIZVODNJE MREŽA</Link></li>



            </ul>
        </div>




    </div>
</div>
<div className="bgc">
  <img src={nav2} className="img-fluid" />
</div>
</div>
 
}


            </div>
          
        )
    }
}

export default Navigacija




