import React, { Component } from 'react';
import Navigacija from '../containers/navbar';
import TrialVersion from '../containers/trialVersion.js';
import Partners from '../containers/partners.js';
import About from '../containers/about.js';
import Strategy from '../containers/strategy.js';
import Footer from '../containers/footer.js';
import Carousel from '../containers/carousel.js';
import Services from '../containers/slickSlider/services.js';
import PriceList from '../containers/slickSlider/priceList.js';
import Isvg from 'react-inlinesvg';
import SkrolDole from '../images/svg/skrolDole.svg';
import Map from '../containers/map.js';
import DefayltLayout from '../containers/defayltLayout.js';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

 class Home extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        const lang = 44.625690;
        const lat = 17.373042;
        const lang2 = 44.7569511;
        const lat2 = 19.2150224;
        return (
            <div>
                <div className="headerBGWrapper">
                   
                    <Carousel />
                    <div className="skrolDole">
                        <button><Isvg src={SkrolDole} />Skroluj</button>
                    </div>

                </div>
                <TrialVersion />
                <About />
                <Strategy />
                <Services />
                <PriceList />
                <Partners />
                <Row noGutters>
                <Col xl="12" lg="12" md="12" xs="12">
                        <Map {...this.props} lang={lang} lat={lat} />
                    </Col>
                    
                </Row>
            
            </div>
        )
    }

}



export default DefayltLayout(Home)