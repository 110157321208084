import React from 'react';
import mapIcon from '../images/pin.png';
import {
  Container,
  Row,

} from 'reactstrap';


export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.state = {};
  }

  initMap() {
    console.log("InitMAP");
    this.setState({
      _mapInit: true
    });
    var latLng = new window.google.maps.LatLng(this.props.lang || this.props.lang2 , this.props.lat || this.props.lat2);

    var map = new window.google.maps.Map(this.GoogleMap, {
      zoom: 18,
      center: latLng,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      gestureHandling: "gestureHandling"
  
    });


    var marker = new window.google.maps.Marker({
      position: latLng,
      map: map,
      icon: mapIcon
    });

    marker.addListener('click', function() {
      if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") != -1) || 
       (navigator.platform.indexOf("iPad") != -1) || 
       (navigator.platform.indexOf("iPod") != -1)){
        //alert('ios');
        
        window.location.href  = ("maps://maps.apple.com/?q=44.625690, 17.373042");
       }else /* else use Google */
       window.location.href = ("https://maps.google.com/maps?daddr=44.625690, 17.373042&amp;ll=");
    });


  }

  componentDidMount() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }

  }

  componentDidUpdate() {
    if (this.props.mapinit && !this.state._mapInit) {
      this.initMap();
    }
  }

  render() {
    return (
      <div>

        {
          this.props.mapinit ?
            <div className="map1" ref={(input) => { this.GoogleMap = input; }}>
           
            </div>
            : null
        }

      </div>
    );
  }
}
