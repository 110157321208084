import React, { Component } from 'react'
import {Container, Row, Col} from 'reactstrap';
import Bgc from '../images/AZURE/bgc.png';
import Clock  from "../images/AZURE/clock.png";
import Icon from "../images/AZURE/icon.png";
import  kartice from "../images/AZURE/kartice.png";
import siskartice from "../images/AZURE/siskartice.png";
export class marketing extends Component {
    render() {
        return (
            <div className="marketing">

                <div className="boxMark">
                <div className="wrapp">
                    <img src={Clock} className="img-fluid"/>
                    <h4>Inovativan pristup</h4>
                    </div>
                </div>       
                
                <div className="boxMark">
                <div className="wrapp">

                <img src={kartice} className="img-fluid"/>

                    <h4>Kvalitetan proizvod</h4>
                    </div>
                </div>        
                
                <div className="boxMark">
                <div className="wrapp">

                <img src={Icon} className="img-fluid"/>

                    <h4>Integracija sistema</h4>
                </div>        
                </div>        
                
                <div className="boxMark">
                <div className="wrapp">

                <img src={siskartice} className="img-fluid"/>

                    <h4>Softverska rešenja</h4>
                    </div>
                </div>                 
            </div>
        )
    }
}

export default marketing
